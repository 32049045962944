import { TitleBar } from '@shopify/app-bridge-react';
import { Layout, Page as PolarisPage } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import ReviewBannerContainer from '../containers/ReviewBannerContainer';

Page.propTypes = {
	account: PropTypes.shape({}).isRequired,
	pageTitle: PropTypes.string,
	title: PropTypes.string,
	secondaryActions: PropTypes.array,
	primaryAction: PropTypes.object,
	breadcrumbs: PropTypes.array,
	actionGroups: PropTypes.array,
	children: PropTypes.any
};

export default function Page({
	account,
	pageTitle = null,
	title = null,
	secondaryActions = [],
	primaryAction,
	breadcrumbs,
	actionGroups,
	children,
	...rest
}) {
	const location = useLocation();

	useEffect(() => {
		const calculatedPageTitle = pageTitle || title;
		document.title = `Mail Connect - ${calculatedPageTitle}`;
	}, [pageTitle, title]);

	const isDashboard = location.pathname === '/dashboard';

	return (
		<PolarisPage {...rest}>
			<TitleBar
				title={title}
				primaryAction={primaryAction}
				secondaryActions={[
					{
						content: 'View help center',
						url: 'https://help.seguno.com/collection/78-mailchimp-connect',
						external: true
					},
					...secondaryActions
				]}
				breadcrumbs={breadcrumbs}
				actionGroups={actionGroups}
			/>
			<Layout>
				{isDashboard && <ReviewBannerContainer />}
				<Layout.Section>{children}</Layout.Section>
			</Layout>
		</PolarisPage>
	);
}
