import { connect } from 'react-redux';
import { mailChimpDisconnect, mailChimpGetList } from '../../actions/mailChimp';
import MailChimpConnection from '../../components/dashboard/MailChimpConnection';
import { getAccount, getMailChimpList, isMailChimpDisconnectLoading, isMailChimpListLoading } from '../../reducers';

const mapStateToProps = state => ({
	account: getAccount(state),
	isDisconnectLoading: isMailChimpDisconnectLoading(state),
	getMailChimpList: id => getMailChimpList(state, id),
	isMailChimpListLoading: id => isMailChimpListLoading(state, id)
});

const mapDispatchToProps = {
	onDisconnect: mailChimpDisconnect,
	onLoadMailChimpList: mailChimpGetList
};

export default connect(mapStateToProps, mapDispatchToProps)(MailChimpConnection);
