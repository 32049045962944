import { Card, Stack } from '@segunosoftware/equinox';
import { Button, ButtonGroup, FormLayout, Layout } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { Component } from 'react';
import MailChimpListPickerContainer from '../../containers/MailChimpListPickerContainer';
import PageContainer from '../../containers/PageContainer';
import { useNavigation } from '../../hooks/useNavigation';

export default function MailChimpFinishConnectionWrapper({ ...rest }) {
	const { onNavigateSettings } = useNavigation();

	return <MailChimpFinishConnection {...rest} onDone={onNavigateSettings} />;
}

class MailChimpFinishConnection extends Component {
	static propTypes = {
		account: PropTypes.shape({
			mailChimpAuthenticated: PropTypes.bool.isRequired
		}).isRequired,
		onFinish: PropTypes.func.isRequired,
		onDone: PropTypes.func.isRequired,
		isFinishing: PropTypes.bool
	};

	static defaultProps = {
		isDisconnectLoading: false,
		isFinishing: false
	};

	state = {
		settings: {
			listId: undefined
		}
	};

	componentDidMount() {
		const { onDone } = this.props;
		if (!this.isConnected()) {
			onDone();
			return;
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { isFinishing, onDone } = this.props;
		if (prevProps.isFinishing && !isFinishing) {
			onDone();
			return;
		}
	}

	isConnected() {
		return this.props.account.mailChimpAuthenticated;
	}

	onSetSettings = newSettings => {
		this.setState(state => ({
			...state,
			settings: {
				...state.settings,
				...newSettings
			}
		}));
	};

	render() {
		const { isFinishing, onFinish, onDone } = this.props;
		const { settings } = this.state;
		return (
			<PageContainer title="Connect Mailchimp" narrowWidth>
				<Layout sectioned>
					<Card title="Select Mailchimp audience">
						<Card.Section>
							<FormLayout>
								<MailChimpListPickerContainer
									label="Audience"
									list={settings.listId}
									helpText="Only one audience may be selected and cannot be changed."
									onChange={listId => this.onSetSettings({ listId })}
									labelHidden
								/>
							</FormLayout>
						</Card.Section>
						<Card.Section>
							<Stack distribution="trailing">
								<ButtonGroup>
									<Button onClick={onDone}>Cancel</Button>
									<Button onClick={() => onFinish(settings)} disabled={!settings.listId} loading={isFinishing} variant="primary">
										Connect
									</Button>
								</ButtonGroup>
							</Stack>
						</Card.Section>
					</Card>
				</Layout>
			</PageContainer>
		);
	}
}
