import { combineReducers } from 'redux';
import account, * as fromAccount from './account';
import app, * as fromApp from './app';
import auth, * as fromAuth from './auth';
import mailChimp, * as fromMailChimp from './mailChimp';
import oauth, * as fromOAuth from './oauth';

export default combineReducers({
	app,
	auth,
	oauth,
	account,
	mailChimp
});

export const getAppError = state => fromApp.getAppError(state.app);
export const isLoading = state =>
	isPerformingWhoAmI(state) ||
	isMailChimpConnectLoading(state) ||
	isMailChimpConnectedLoading(state) ||
	isMailChimpDisconnectLoading(state) ||
	isFinishMailChimpConnectionLoading(state) ||
	isMailChimpListsLoading(state) ||
	isSettingsSaving(state) ||
	isMailChimpMergeFieldsLoading(state) ||
	isSyncStatusLoading(state) ||
	isStartSyncLoading(state);

export const isPerformingWhoAmI = state => fromAuth.isPerformingWhoAmI(state.auth);
export const getLoginFailureMessage = state => fromAuth.getLoginFailureMessage(state.auth);
export const getAccount = state => fromAuth.getAccount(state.auth);

export const getInstallShopifyRedirectUrl = state => fromOAuth.getInstallShopifyRedirectUrl(state.oauth);

export const isSettingsSaving = state => fromAccount.isSettingsSaving(state.account);
export const isFinishMailChimpConnectionLoading = state => fromAccount.isFinishMailChimpConnectionLoading(state.account);
export const isSyncStatusLoading = state => fromAccount.isSyncStatusLoading(state.account);
export const isStartSyncLoading = state => fromAccount.isStartSyncLoading(state.account);

export const getMailChimpConnectUrl = state => fromMailChimp.getMailChimpConnectUrl(state.mailChimp);
export const isMailChimpConnectLoading = state => fromMailChimp.isMailChimpConnectLoading(state.mailChimp);
export const isMailChimpConnectedLoading = state => fromMailChimp.isMailChimpConnectedLoading(state.mailChimp);
export const isMailChimpDisconnectLoading = state => fromMailChimp.isMailChimpDisconnectLoading(state.mailChimp);
export const getMailChimpConnectedError = state => fromMailChimp.getMailChimpConnectedError(state.mailChimp);
export const getMailChimpLists = state => fromMailChimp.getMailChimpLists(state.mailChimp);
export const isMailChimpListsLoading = state => fromMailChimp.isMailChimpListsLoading(state.mailChimp);
export const getMailChimpList = (state, id) => fromMailChimp.getMailChimpList(state.mailChimp, id);
export const isMailChimpListLoading = (state, id) => fromMailChimp.isMailChimpListLoading(state.mailChimp, id);
export const getMailChimpMergeFields = state => fromMailChimp.getMailChimpMergeFields(state.mailChimp);
export const isMailChimpMergeFieldsLoading = state => fromMailChimp.isMailChimpMergeFieldsLoading(state.mailChimp);
