import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export function useNavigation() {
	const navigate = useNavigate();

	return useMemo(
		() => ({
			onNavigateRoot: () => navigate('/'),
			onNavigateSettings: () => navigate('/settings'),
			onNavigateConnectMailchimp: () => navigate('/oauth/mailchimp/connect')
		}),
		[navigate]
	);
}
