import { connect } from 'react-redux';
import { mailChimpConnected } from '../../actions/mailChimp';
import MailChimpCallback from '../../components/oauth/MailChimpCallback';
import { getMailChimpConnectedError, isMailChimpConnectedLoading } from '../../reducers';

const mapStateToProps = state => ({
	isConnectedLoading: isMailChimpConnectedLoading(state),
	error: getMailChimpConnectedError(state)
});

const mapDispatchToProps = {
	onConnected: mailChimpConnected
};

export default connect(mapStateToProps, mapDispatchToProps)(MailChimpCallback);
