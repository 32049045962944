import { connect } from 'react-redux';
import { startSync } from '../../actions/account';
import Dashboard from '../../components/dashboard/Dashboard';
import { getAccount, isStartSyncLoading } from '../../reducers';

const mapStateToProps = state => ({
	account: getAccount(state),
	isStartSyncLoading: isStartSyncLoading(state)
});

const mapDispatchToProps = {
	onStartSync: startSync
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
