import { connect } from 'react-redux';
import { mailChimpGetLists } from '../actions/mailChimp';
import MailChimpListPicker from '../components/MailChimpListPicker';
import { getMailChimpLists, isMailChimpListsLoading } from '../reducers';

const mapStateToProps = state => ({
	lists: getMailChimpLists(state),
	loading: isMailChimpListsLoading(state)
});

const mapDispatchToProps = {
	onLoadLists: mailChimpGetLists
};

export default connect(mapStateToProps, mapDispatchToProps)(MailChimpListPicker);
