import { Select } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { Component } from 'react';

export const FIELD_TYPES = {
	FIRST_NAME: {
		label: 'First name',
		position: 1
	},
	LAST_NAME: {
		label: 'Last name',
		position: 2
	},
	PHONE: {
		label: 'Phone',
		types: ['text', 'phone'],
		position: 3
	},
	DEFAULT_ADDRESS: {
		label: 'Default address',
		types: ['address'],
		position: 4
	},
	DEFAULT_STREET1: {
		label: 'Street 1 of default address',
		position: 5
	},
	DEFAULT_STREET2: {
		label: 'Street 2 of default address',
		position: 6
	},
	DEFAULT_CITY: {
		label: 'City of default address',
		position: 7
	},
	DEFAULT_PROVINCE: {
		label: 'State/province of default address',
		position: 8
	},
	DEFAULT_ZIP: {
		label: 'Postal code of default address',
		types: ['text', 'zip'],
		position: 9
	},
	DEFAULT_COUNTRY: {
		label: 'Country of default address',
		position: 10
	},
	DEFAULT_PHONE: {
		label: 'Phone of default address',
		types: ['text', 'phone'],
		position: 11
	},
	DEFAULT_COMPANY: {
		label: 'Company of default address',
		position: 12
	}
};

export default class FieldMapping extends Component {
	static propTypes = {
		onChange: PropTypes.func.isRequired,
		shopifyField: PropTypes.oneOf(Object.keys(FIELD_TYPES)).isRequired,
		mergeFields: PropTypes.arrayOf(
			PropTypes.shape({
				tag: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
				type: PropTypes.oneOf(['text', 'number', 'address', 'phone', 'date', 'url', 'imageurl', 'radio', 'dropdown', 'birthday', 'zip'])
					.isRequired,
				displayOrder: PropTypes.number.isRequired
			})
		),
		unavailableMergeFields: PropTypes.arrayOf(PropTypes.string),
		value: PropTypes.string
	};

	static defaultProps = {
		mergeFields: [],
		unavailableMergeFields: []
	};

	render() {
		const { onChange, shopifyField, value, mergeFields, unavailableMergeFields, ...rest } = this.props;
		const fieldType = FIELD_TYPES[shopifyField];
		const allowedTypes = fieldType.types || ['text'];
		const options = mergeFields
			.filter(field => field.tag === value || !unavailableMergeFields.includes(field.tag))
			.filter(field => allowedTypes.includes(field.type))
			.sort((a, b) => a.displayOrder - b.displayOrder)
			.map(field => ({ value: field.tag, label: `${field.name} (${field.type}; ${field.tag})` }));
		options.unshift({ value: '', label: 'Not mapped' });
		return <Select {...rest} label={fieldType.label} options={options} onChange={onChange} value={value || ''} />;
	}
}
