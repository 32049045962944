import { callApi } from '../middleware/api';

export const WHOAMI = 'auth/WHOAMI';
export const WHOAMI_SUCCESS = 'auth/WHOAMI_SUCCESS';
export const WHOAMI_ERROR = 'auth/WHOAMI_ERROR';

export const loadAccount = () =>
	callApi('/auth/whoami', {
		before: WHOAMI,
		success: WHOAMI_SUCCESS,
		error: WHOAMI_ERROR
	});
