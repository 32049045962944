import { connect } from 'react-redux';
import ShopifyOAuthInstall from '../../components/oauth/ShopifyOAuthInstall';
import { getInstallShopifyRedirectUrl } from '../../reducers';

const mapStateToProps = state => ({
	redirectUrl: getInstallShopifyRedirectUrl(state)
});

const mapDispatchToProps = onInstall => ({
	onOAuthInstall: onInstall
});

const shopifyInstallContainer = onInstall => connect(mapStateToProps, mapDispatchToProps(onInstall))(ShopifyOAuthInstall);

export default shopifyInstallContainer;
