import { connect } from 'react-redux';
import ShopifyOAuthCallback from '../../components/oauth/ShopifyOAuthCallback';
import { getInstallShopifyRedirectUrl } from '../../reducers';

const mapStateToProps = state => ({
	redirectUrl: getInstallShopifyRedirectUrl(state)
});

const mapDispatchToProps = callback => ({
	onOAuthCallback: callback
});

const shopifyCallbackContainer = callback => connect(mapStateToProps, mapDispatchToProps(callback))(ShopifyOAuthCallback);

export default shopifyCallbackContainer;
