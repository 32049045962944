import { Stack, getNumber } from '@segunosoftware/equinox';
import { Labelled, ProgressBar } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { Component } from 'react';

const MILLIS_PER_SECOND = 1000;
const REFRESH_INTERVAL = 5 * MILLIS_PER_SECOND;

export default class SyncStatus extends Component {
	static propTypes = {
		account: PropTypes.shape({
			syncStatus: PropTypes.shape({
				shopifyTotalCustomers: PropTypes.number.isRequired,
				shopifyTotalOrders: PropTypes.number.isRequired,
				shopifyTotalProducts: PropTypes.number.isRequired,
				shopifyTotalAbandonedCheckouts: PropTypes.number.isRequired,
				mailchimpTotalMembers: PropTypes.number.isRequired,
				mailchimpTotalMembersSynced: PropTypes.number.isRequired,
				shopifyTotalCustomersSynced: PropTypes.number.isRequired,
				shopifyTotalOrdersSynced: PropTypes.number.isRequired,
				shopifyTotalProductsSynced: PropTypes.number.isRequired,
				shopifyTotalAbandonedCheckoutsSynced: PropTypes.number.isRequired,
				initialSyncComplete: PropTypes.bool.isRequired
			}).isRequired
		}).isRequired,
		onLoadStats: PropTypes.func.isRequired,
		isLoadingStats: PropTypes.bool.isRequired
	};

	componentDidMount() {
		this.onPerformLoadStats();
		this.attemptToStartSyncing();
	}

	componentDidUpdate(prevProps, prevState) {
		this.attemptToStartSyncing();
	}

	componentWillUnmount() {
		this.cancelSyncing();
	}

	attemptToStartSyncing() {
		const {
			account: {
				syncStatus: { initialSyncComplete }
			}
		} = this.props;
		if (initialSyncComplete) {
			this.cancelSyncing();
		} else if (!this.refreshInterval) {
			this.refreshInterval = setInterval(this.onPerformLoadStats, REFRESH_INTERVAL);
		}
	}

	cancelSyncing() {
		clearInterval(this.refreshInterval);
		this.refreshInterval = null;
	}

	onPerformLoadStats = () => {
		const { onLoadStats, isLoadingStats } = this.props;
		if (!isLoadingStats) {
			onLoadStats();
		}
	};

	renderStatus(label, total, completed) {
		const {
			account: {
				syncStatus: { initialSyncComplete }
			}
		} = this.props;
		const absoluteTotal = Math.max(total, completed);
		const progress = initialSyncComplete ? 100 : total === 0 ? 0 : (completed / absoluteTotal) * 100.0;
		return (
			<Labelled label={`${label} - ${getNumber(completed)} of ${getNumber(absoluteTotal)}`}>
				<ProgressBar progress={progress} />
			</Labelled>
		);
	}

	render() {
		const {
			account: {
				syncStatus: {
					shopifyTotalCustomers,
					shopifyTotalOrders,
					shopifyTotalProducts,
					shopifyTotalAbandonedCheckouts,
					mailchimpTotalMembers,
					mailchimpTotalMembersSynced,
					shopifyTotalCustomersSynced,
					shopifyTotalOrdersSynced,
					shopifyTotalProductsSynced,
					shopifyTotalAbandonedCheckoutsSynced
				}
			}
		} = this.props;
		return (
			<Stack vertical>
				{this.renderStatus('Mailchimp contacts', mailchimpTotalMembers, mailchimpTotalMembersSynced)}
				{this.renderStatus('Shopify customers', shopifyTotalCustomers, shopifyTotalCustomersSynced)}
				{this.renderStatus('Shopify products', shopifyTotalProducts, shopifyTotalProductsSynced)}
				{this.renderStatus('Shopify orders', shopifyTotalOrders, shopifyTotalOrdersSynced)}
				{this.renderStatus('Shopify abandoned checkouts', shopifyTotalAbandonedCheckouts, shopifyTotalAbandonedCheckoutsSynced)}
			</Stack>
		);
	}
}
