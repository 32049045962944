import { LoadingScreen } from '@segunosoftware/equinox';
import { Context } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import { Page } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { Component } from 'react';

export default class MailChimpConnect extends Component {
	static propTypes = {
		onGetRedirectUrl: PropTypes.func.isRequired,
		redirectUrlLoading: PropTypes.bool,
		redirectUrl: PropTypes.string
	};

	static defaultProps = {
		redirectUrlLoading: false
	};

	static contextType = Context;

	componentDidMount() {
		this.attemptRedirect();
	}

	componentDidUpdate(prevProps, prevState) {
		this.attemptRedirect();
	}

	attemptRedirect() {
		const { redirectUrl, redirectUrlLoading, onGetRedirectUrl } = this.props;
		if (!redirectUrl && !redirectUrlLoading) {
			onGetRedirectUrl();
		} else if (redirectUrl) {
			const app = this.context;
			const redirect = Redirect.create(app);
			redirect.dispatch(Redirect.Action.REMOTE, redirectUrl);
		}
	}

	render() {
		return (
			<Page>
				<LoadingScreen>Redirecting to Mailchimp...</LoadingScreen>
			</Page>
		);
	}
}
