import { callApi } from '../middleware/api';

export const SHOPIFY_INSTALL = 'oauth/SHOPIFY_INSTALL';
export const SHOPIFY_INSTALL_SUCCESS = 'oauth/SHOPIFY_INSTALL_SUCCESS';
export const SHOPIFY_INSTALL_ERROR = 'oauth/SHOPIFY_INSTALL_ERROR';

export const SHOPIFY_CALLBACK = 'oauth/SHOPIFY_CALLBACK';
export const SHOPIFY_CALLBACK_SUCCESS = 'oauth/SHOPIFY_CALLBACK_SUCCESS';
export const SHOPIFY_CALLBACK_ERROR = 'oauth/SHOPIFY_CALLBACK_ERROR';

export const shopifyInstallOAuth = parameters =>
	callApi(`/oauth/shopify/install?${parameters}`, {
		before: SHOPIFY_INSTALL,
		success: SHOPIFY_INSTALL_SUCCESS,
		error: SHOPIFY_INSTALL_ERROR,
		unprotected: true
	});

export const shopifyOAuthCallback = parameters =>
	callApi(`/oauth/shopify/callback?${parameters}`, {
		before: SHOPIFY_CALLBACK,
		success: SHOPIFY_CALLBACK_SUCCESS,
		error: SHOPIFY_CALLBACK_ERROR,
		unprotected: true
	});

export const shopifyBillingInitiate = parameters =>
	callApi(`/oauth/shopify/billing/initiate?${parameters}`, {
		before: SHOPIFY_INSTALL,
		success: SHOPIFY_INSTALL_SUCCESS,
		error: SHOPIFY_INSTALL_ERROR,
		unprotected: true
	});

export const shopifyBillingCallback = parameters =>
	callApi(`/oauth/shopify/billing/callback?${parameters}`, {
		before: SHOPIFY_CALLBACK,
		success: SHOPIFY_CALLBACK_SUCCESS,
		error: SHOPIFY_CALLBACK_ERROR,
		unprotected: true
	});
