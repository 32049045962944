import { callApi } from '../middleware/api';

export const SET_REVIEW_SENTIMENT = 'account/SET_REVIEW_SENTIMENT';
export const SET_REVIEW_SENTIMENT_SUCCESS = 'account/SET_REVIEW_SENTIMENT_SUCCESS';
export const SET_REVIEW_SENTIMENT_ERROR = 'account/SET_REVIEW_SENTIMENT_ERROR';

export const SET_REVIEW_STATUS = 'account/SET_REVIEW_STATUS';
export const SET_REVIEW_STATUS_SUCCESS = 'account/SET_REVIEW_STATUS_SUCCESS';
export const SET_REVIEW_STATUS_ERROR = 'account/SET_REVIEW_STATUS_ERROR';

export const SAVE_SETTINGS = 'account/SAVE_SETTINGS';
export const SAVE_SETTINGS_SUCCESS = 'account/SAVE_SETTINGS_SUCCESS';
export const SAVE_SETTINGS_ERROR = 'account/SAVE_SETTINGS_ERROR';

export const COMPLETE_MAIL_CHIMP_CONNECTION = 'account/COMPLETE_MAIL_CHIMP_CONNECTION';
export const COMPLETE_MAIL_CHIMP_CONNECTION_SUCCESS = 'account/COMPLETE_MAIL_CHIMP_CONNECTION_SUCCESS';
export const COMPLETE_MAIL_CHIMP_CONNECTION_ERROR = 'account/COMPLETE_MAIL_CHIMP_CONNECTION_ERROR';

export const LOAD_SYNC_STATUS = 'account/LOAD_SYNC_STATUS';
export const LOAD_SYNC_STATUS_SUCCESS = 'account/LOAD_SYNC_STATUS_SUCCESS';
export const LOAD_SYNC_STATUS_ERROR = 'account/LOAD_SYNC_STATUS_ERROR';

export const START_SYNC = 'account/START_SYNC';
export const START_SYNC_SUCCESS = 'account/START_SYNC_SUCCESS';
export const START_SYNC_ERROR = 'account/LOAD_SYNC_STATUS_ERROR';

const setReviewSentimentAction = reviewSentiment => ({
	type: SET_REVIEW_SENTIMENT,
	payload: reviewSentiment
});

const setReviewSentimentSuccessAction = reviewSentiment => ({
	type: SET_REVIEW_SENTIMENT_SUCCESS,
	payload: reviewSentiment
});

const setReviewSentimentErrorAction = reviewSentiment => ({
	type: SET_REVIEW_SENTIMENT_ERROR,
	payload: reviewSentiment
});

export const setReviewSentiment = reviewSentiment =>
	callApi('/account/review-sentiment', {
		before: setReviewSentimentAction(reviewSentiment),
		success: setReviewSentimentSuccessAction(reviewSentiment),
		error: setReviewSentimentErrorAction(reviewSentiment),
		config: {
			method: 'POST',
			body: {
				reviewSentiment
			}
		}
	});

const setReviewStatusAction = reviewStatus => ({
	type: SET_REVIEW_STATUS,
	payload: reviewStatus
});

const setReviewStatusSuccessAction = reviewStatus => ({
	type: SET_REVIEW_STATUS_SUCCESS,
	payload: reviewStatus
});

const setReviewStatusErrorAction = reviewStatus => ({
	type: SET_REVIEW_STATUS_ERROR,
	payload: reviewStatus
});

export const setReviewStatus = reviewStatus =>
	callApi('/account/review-status', {
		before: setReviewStatusAction(reviewStatus),
		success: setReviewStatusSuccessAction(reviewStatus),
		error: setReviewStatusErrorAction(reviewStatus),
		config: {
			method: 'POST',
			body: {
				reviewStatus
			}
		}
	});

const saveSettingsAction = settings => ({
	type: SAVE_SETTINGS,
	payload: settings
});

const saveSettingsSuccessAction = settings => ({
	type: SAVE_SETTINGS_SUCCESS,
	payload: settings
});

const saveSettingsErrorAction = settings => ({
	type: SAVE_SETTINGS_ERROR,
	payload: settings
});

export const saveSettings = settings =>
	callApi('/account/settings', {
		before: saveSettingsAction(settings),
		success: saveSettingsSuccessAction(settings),
		error: saveSettingsErrorAction(settings),
		config: {
			method: 'PUT',
			body: settings
		}
	});

const completeMailChimpConnectionAction = listId => ({
	type: COMPLETE_MAIL_CHIMP_CONNECTION,
	payload: listId
});

const completeMailChimpConnectionErrorAction = listId => ({
	type: COMPLETE_MAIL_CHIMP_CONNECTION_ERROR,
	payload: listId
});

export const completeMailChimpConnection = settings =>
	callApi('/account/complete-mailchimp-connection', {
		before: completeMailChimpConnectionAction(settings.listId),
		success: COMPLETE_MAIL_CHIMP_CONNECTION_SUCCESS,
		error: completeMailChimpConnectionErrorAction(settings.listId),
		config: {
			method: 'POST',
			body: {
				...settings
			}
		}
	});

export const loadSyncStatus = () =>
	callApi('/account/sync-status', {
		before: LOAD_SYNC_STATUS,
		success: LOAD_SYNC_STATUS_SUCCESS,
		error: LOAD_SYNC_STATUS_ERROR
	});

export const startSync = () =>
	callApi('/account/start-sync', {
		before: START_SYNC,
		success: START_SYNC_SUCCESS,
		error: START_SYNC_ERROR,
		config: {
			method: 'POST'
		}
	});
