export const ERROR = 'app/ERROR';
export const CLEAR_ERROR = 'app/CLEAR_ERROR';

export const appError = error => ({
	type: ERROR,
	payload: error
});

export const clearError = () => ({
	type: CLEAR_ERROR
});
