import { Route, Routes } from 'react-router-dom';
import { shopifyBillingCallback, shopifyBillingInitiate, shopifyInstallOAuth, shopifyOAuthCallback } from '../../actions/oauth';
import MailChimpCallbackContainer from '../../containers/oauth/MailChimpCallbackContainer';
import ShopifyCallbackContainer from '../../containers/oauth/ShopifyCallbackContainer';
import ShopifyInstallContainer from '../../containers/oauth/ShopifyInstallContainer';

const ShopifyInstallComponent = ShopifyInstallContainer(shopifyInstallOAuth);
const ShopifyCallbackComponent = ShopifyCallbackContainer(shopifyOAuthCallback);
const ShopifyBillingInstallComponent = ShopifyInstallContainer(shopifyBillingInitiate);
const ShopifyBillingCallbackComponent = ShopifyCallbackContainer(shopifyBillingCallback);

export default function OAuth() {
	return (
		<Routes>
			<Route path="shopify/install" element={<ShopifyInstallComponent />} />
			<Route path="shopify/callback" element={<ShopifyCallbackComponent />} />
			<Route path="shopify/billing/initiate" element={<ShopifyBillingInstallComponent />} />
			<Route path="shopify/billing/callback" element={<ShopifyBillingCallbackComponent />} />
			<Route path="mailchimp/callback" element={<MailChimpCallbackContainer />} />
		</Routes>
	);
}
