import { connect } from 'react-redux';
import { completeMailChimpConnection } from '../../actions/account';
import MailChimpFinishConnection from '../../components/settings/MailChimpFinishConnection';
import { getAccount, isFinishMailChimpConnectionLoading } from '../../reducers';

const mapStateToProps = state => ({
	account: getAccount(state),
	isFinishing: isFinishMailChimpConnectionLoading(state)
});

const mapDispatchToProps = {
	onFinish: completeMailChimpConnection
};

export default connect(mapStateToProps, mapDispatchToProps)(MailChimpFinishConnection);
