import { connect } from 'react-redux';
import { setReviewSentiment, setReviewStatus } from '../actions/account';
import ReviewBanner from '../components/ReviewBanner';
import { getAccount } from '../reducers';

const mapStateToProps = state => ({
	account: getAccount(state)
});

const mapDispatchToProps = {
	onSetReviewSentiment: setReviewSentiment,
	onSetReviewStatus: setReviewStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewBanner);
