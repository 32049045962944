import { combineReducers } from 'redux';
import { CLEAR_ERROR, ERROR } from '../actions/app';

const error = (state = null, action) => {
	switch (action.type) {
		case ERROR:
			return action.payload;
		case CLEAR_ERROR:
			return null;
		default:
			return state;
	}
};

export default combineReducers({
	error
});

export const getAppError = state => state.error;
