import { LoadingScreen } from '@segunosoftware/equinox';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Navigate } from 'react-router-dom';

export default class ShopifyOAuthInstall extends Component {
	static propTypes = {
		onOAuthInstall: PropTypes.func.isRequired,
		redirectUrl: PropTypes.string
	};

	state = {
		shop: null
	};

	componentDidMount() {
		const { onOAuthInstall } = this.props;
		const queryParams = window.location.search.substring(1);
		const vars = queryParams.split('&');
		for (let i = 0; i < vars.length; i++) {
			const [key, ...rest] = vars[i].split('=');
			const value = rest.join('=');
			if (key === 'shop') {
				this.setState({
					shop: value
				});
			}
		}
		onOAuthInstall(queryParams);
	}

	componentDidUpdate(prevProps, prevState) {
		const { redirectUrl } = this.props;
		if (prevProps.redirectUrl !== redirectUrl && redirectUrl && redirectUrl.indexOf('/') !== 0) {
			const { shop } = this.state;
			const windowLoc = redirectUrl.indexOf(`https://${shop}`) === 0 ? window.top : window;
			windowLoc.location = redirectUrl;
		}
	}

	render() {
		const { redirectUrl } = this.props;
		if (redirectUrl && redirectUrl.indexOf('/') === 0) {
			return <Navigate to={redirectUrl} />;
		}
		return <LoadingScreen>Connecting to Shopify...</LoadingScreen>;
	}
}
