import { connect } from 'react-redux';
import { loadSyncStatus } from '../../actions/account';
import SyncStatus from '../../components/dashboard/SyncStatus';
import { getAccount, isSyncStatusLoading } from '../../reducers';

const mapStateToProps = state => ({
	account: getAccount(state),
	isLoadingStats: isSyncStatusLoading(state)
});

const mapDispatchToProps = {
	onLoadStats: loadSyncStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(SyncStatus);
