import { connect } from 'react-redux';
import { saveSettings } from '../../actions/account';
import { mailChimpGetMergeFields } from '../../actions/mailChimp';
import Settings from '../../components/settings/Settings';
import { getAccount, getMailChimpMergeFields, isMailChimpMergeFieldsLoading, isSettingsSaving } from '../../reducers';

const mapStateToProps = state => ({
	account: getAccount(state),
	isSaving: isSettingsSaving(state),
	mergeFields: getMailChimpMergeFields(state),
	isMergeFieldsLoading: isMailChimpMergeFieldsLoading(state)
});

const mapDispatchToProps = {
	onSave: saveSettings,
	onLoadMergeFields: mailChimpGetMergeFields
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
