import { Stack } from '@segunosoftware/equinox';
import { Link } from '@shopify/polaris';
import { Component } from 'react';
import styled from 'styled-components';
import VideoModal from '../VideoModal';

const INSTRUCTIONS = [
	{
		id: 'backupShopify',
		label: (
			<span>
				<Link url="https://help.shopify.com/en/manual/customers/import-export-customers#export-existing-customers-to-a-csv-file" external>
					Export all customers
				</Link>{' '}
				from Shopify.
			</span>
		),
		videoInfo: {
			video: 'https://www.youtube.com/embed/u3hTuKxWU2k?rel=0',
			label: 'Export customers from Shopify'
		}
	},
	{
		id: 'backupMailchimp',
		label: (
			<span>
				<Link url="https://mailchimp.com/help/view-export-contacts/" external>
					Export all contacts
				</Link>{' '}
				from Mailchimp.
			</span>
		),
		videoInfo: {
			video: 'https://www.youtube.com/embed/HPAcQdWU2EA?rel=0',
			label: 'Export contacts from Mailchimp'
		}
	},
	{
		id: 'connectMailchimp',
		label: 'Connect to Mailchimp and select your audience.',
		videoInfo: {
			video: 'https://www.youtube.com/embed/gJTTqZTpldE?rel=0',
			label: 'Connect to Mailchimp'
		}
	},
	{
		id: 'mapFields',
		label: 'Map Shopify fields to Mailchimp merge fields on the Settings page.',
		videoInfo: {
			video: 'https://www.youtube.com/embed/qvC1JWi6lgc?rel=0',
			label: 'Map fields'
		}
	},
	{
		id: 'turnOffNotifications',
		label: (
			<span>
				<Link url="https://mailchimp.com/help/change-subscribe-and-unsubscribe-notifications/#Enable_or_Disable_Notifications" external>
					Turn off new subscriber notifications
				</Link>{' '}
				for your audience.
			</span>
		),
		videoInfo: {
			video: 'https://www.youtube.com/embed/r8QXWzOmx-k?rel=0',
			label: 'Turn off subscriber notifications'
		}
	},
	{
		id: 'migrateAutomations',
		label:
			'If previously integrated, move Abandoned cart and Order notifications tied to the original store by editing the recipients and changing the store. ',
		videoInfo: {
			video: 'https://www.youtube.com/embed/oo1jvUqLAIw?rel=0',
			label: 'Move automations to new connected store'
		}
	},
	{
		id: 'disconnectMailchimpApp',
		label: (
			<span>
				<Link url="https://mailchimp.com/help/about-connected-sites/#Disconnect_From_Your_Mailchimp_Account" external>
					Disconnect the Mailchimp app
				</Link>
				. Do not disconnect the site created by this app, which has "(Mail Connect)" at the end of the name.
			</span>
		),
		videoInfo: {
			video: 'https://www.youtube.com/embed/63xFzoWJPN4?rel=0',
			label: 'Disconnect Mailchimp for Shopify connected site'
		}
	},
	{
		id: 'startSync',
		label: 'Start sync from the Dashboard.',
		videoInfo: {
			video: 'https://www.youtube.com/embed/AmiPXzL-Yz0?rel=0',
			label: 'Start sync'
		}
	},
	{
		id: 'turnOnNotifications',
		label: 'Turn back on any previously disabled subscriber notifications for your audience.',
		videoInfo: {
			video: 'https://www.youtube.com/embed/zKRoOdNGOtw?rel=0',
			label: 'Turn back on subscriber notifications'
		}
	},
	{
		id: 'rebuildAutomations',
		label: 'Product retargeting and any other automations created outside the original store will need to be rebuilt for the new store.',
		videoInfo: {
			video: 'https://www.youtube.com/embed/UG_ZFitZ3Y0?rel=0',
			label: 'Rebuild product retargeting automations'
		}
	},
	{
		id: 'clearWelcomeAutomationsQueues',
		label: (
			<span>
				If you chose to "Also keep any extra subscribers that are in Shopify" at the top of the Settings page,{' '}
				<Link url="https://mailchimp.com/help/manage-subscribers-in-an-automation/#Remove_Subscriber_from_an_Automation" external>
					remove subscribers
				</Link>{' '}
				tagged with "subscribed-from-shopify" from any welcome automation.
			</span>
		),
		videoInfo: {
			video: 'https://www.youtube.com/embed/msG4HrPKH6o?rel=0',
			label: 'Remove new subscribers from welcome automation queue'
		}
	},
	{
		id: 'enableAutomations',
		label: (
			<span>
				<Link url="https://mailchimp.com/help/edit-an-active-automation/#Pause_or_Resume_Automation" external>
					Turn back on any automations
				</Link>{' '}
				that were paused during the sync.
			</span>
		),
		videoInfo: {
			video: 'https://www.youtube.com/embed/Eb4-_9fZVHo?rel=0',
			label: 'Turn back on automations'
		}
	}
];

export default class Instructions extends Component {
	state = {
		video: null,
		videoTitle: null,
		videoModalShowing: false
	};

	onShowVideo = videoInfo => {
		this.setState({
			video: videoInfo.video,
			videoTitle: videoInfo.label,
			videoModalShowing: true
		});
	};

	renderInstruction = (instruction, index) => {
		return (
			<Instruction key={index}>
				<VideoWithNumber>
					<PlayIcon onClick={() => this.onShowVideo(instruction.videoInfo)} />
					<span style={{ fontVariantNumeric: 'tabular-nums' }}>{index + 1}. </span>
				</VideoWithNumber>
				<span>{instruction.label}</span>
			</Instruction>
		);
	};

	render() {
		const { video, videoTitle, videoModalShowing } = this.state;
		return (
			<>
				<Stack vertical>{INSTRUCTIONS.map(this.renderInstruction)}</Stack>
				<VideoModal video={video} title={videoTitle} open={videoModalShowing} onClose={() => this.setState({ videoModalShowing: false })} />
			</>
		);
	}
}

function PlayIcon({ onClick }) {
	return (
		<PlayLink
			enable-background="new 0 0 314.068 314.068"
			height="314.068"
			viewBox="0 0 314.068 314.068"
			width="314.068"
			xmlns="http://www.w3.org/2000/svg"
			onClick={onClick}>
			<path d="m293.002 78.53c-43.356-75.095-139.384-100.826-214.473-57.462-75.095 43.35-100.827 139.374-57.463 214.466 43.35 75.095 139.375 100.83 214.465 57.47 75.096-43.365 100.84-139.384 57.471-214.474zm-73.168 187.271c-60.067 34.692-136.894 14.106-171.576-45.973-34.69-60.067-14.097-136.893 45.972-171.568 60.071-34.69 136.894-14.106 171.578 45.971 34.685 60.076 14.098 136.886-45.974 171.57zm-6.279-115.149-82.214-47.949c-7.492-4.374-13.535-.877-13.493 7.789l.421 95.174c.038 8.664 6.155 12.191 13.669 7.851l81.585-47.103c7.506-4.332 7.522-11.388.032-15.762z" />
		</PlayLink>
	);
}

const Instruction = styled.div`
	display: flex;
	align-items: flex-start;
`;

const VideoWithNumber = styled.div`
	display: flex;
	align-items: center;
	margin-right: 5px;
`;

const PlayLink = styled.svg`
	width: 20px;
	height: 20px;
	cursor: pointer;
	fill: var(--p-color-text-link);
	margin-right: 13px;
	&:hover {
		fill: var(--p-color-text-link-hover);
	}
`;
