import { LoadingScreen } from '@segunosoftware/equinox';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Component } from 'react';
import { useNavigation } from '../../hooks/useNavigation';

export default function ShopifyOAuthCallbackWrapper({ ...rest }) {
	const { onNavigateRoot } = useNavigation();

	return <ShopifyOAuthCallback {...rest} onNavigateRoot={onNavigateRoot} />;
}

class ShopifyOAuthCallback extends Component {
	static propTypes = {
		onOAuthCallback: PropTypes.func.isRequired,
		onNavigateRoot: PropTypes.func.isRequired,
		redirectUrl: PropTypes.string
	};

	componentDidMount() {
		const { onOAuthCallback, onNavigateRoot } = this.props;
		const params = queryString.parse(window.location.search);
		if (params.state || params.nonce) {
			const queryParams = window.location.search.substring(1);
			onOAuthCallback(queryParams);
		} else {
			onNavigateRoot();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { redirectUrl } = this.props;
		if (prevProps.redirectUrl !== redirectUrl && redirectUrl) {
			window.top.location = redirectUrl;
		}
	}

	render() {
		return <LoadingScreen>Connecting to Shopify...</LoadingScreen>;
	}
}
