import { Modal } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { Component } from 'react';

export default class VideoModal extends Component {
	static propTypes = {
		onClose: PropTypes.func.isRequired,
		title: PropTypes.string,
		video: PropTypes.string,
		open: PropTypes.bool
	};

	static defaultProps = {
		open: false
	};

	render() {
		const { title, video, onClose, open } = this.props;
		return (
			<Modal title={title} onClose={onClose} open={open}>
				<iframe
					title={title}
					width="100%"
					height="400"
					src={video}
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen></iframe>
			</Modal>
		);
	}
}
