import { Card } from '@segunosoftware/equinox';
import { Toast } from '@shopify/app-bridge-react';
import { Banner, ChoiceList, FormLayout, Layout, List, SkeletonBodyText } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { Component } from 'react';
import PageContainer from '../../containers/PageContainer';
import { useNavigation } from '../../hooks/useNavigation';
import FieldMapping, { FIELD_TYPES } from './FieldMapping';

export default function SettingsWrapper({ ...rest }) {
	const { onNavigateRoot } = useNavigation();

	return <Settings {...rest} onCancel={onNavigateRoot} />;
}

class Settings extends Component {
	static propTypes = {
		account: PropTypes.shape({
			mailChimpConnected: PropTypes.bool.isRequired,
			accountSettings: PropTypes.shape({
				shopifySubscribeExisting: PropTypes.bool.isRequired,
				fieldMappings: PropTypes.object.isRequired
			}).isRequired,
			syncStatus: PropTypes.shape({
				initialSyncStarted: PropTypes.bool.isRequired
			}).isRequired
		}).isRequired,
		onSave: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
		mergeFields: PropTypes.array.isRequired,
		onLoadMergeFields: PropTypes.func.isRequired,
		isMergeFieldsLoading: PropTypes.bool.isRequired,
		isSaving: PropTypes.bool
	};

	static defaultProps = {
		isSaving: false
	};

	constructor(props) {
		super(props);
		this.state = {
			errors: {},
			dirty: false,
			showSaveToast: false,
			mergeFieldsLoaded: false,
			accountSettings: { ...props.account.accountSettings }
		};
	}

	componentDidMount() {
		const {
			account: { mailChimpConnected },
			mergeFields,
			onLoadMergeFields,
			isMergeFieldsLoading
		} = this.props;
		if (mailChimpConnected) {
			if ((!mergeFields || mergeFields.length === 0) && !isMergeFieldsLoading) {
				onLoadMergeFields();
			} else {
				this.setState({
					mergeFieldsLoaded: true
				});
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { isSaving, isMergeFieldsLoading, onCancel } = this.props;
		const { mergeFieldsLoaded } = this.state;
		if (!isSaving && prevProps.isSaving) {
			this.setState(
				{
					showSaveToast: true,
					dirty: false,
					errors: {},
					accountSettings: { ...this.props.account.accountSettings }
				},
				() => this.setState({ showSaveToast: false })
			);
			onCancel();
		}
		if (!mergeFieldsLoaded && isMergeFieldsLoading && isMergeFieldsLoading !== prevProps.isMergeFieldsLoading) {
			this.setState({
				mergeFieldsLoaded: true
			});
		}
	}

	onSave = () => {
		const { onSave } = this.props;
		const { accountSettings } = this.state;
		const errors = this.validate(accountSettings);
		this.setState({
			errors
		});
		if (Object.keys(errors).length === 0) {
			onSave(accountSettings);
		}
	};

	validate(accountSettings) {
		const errors = {};
		// if (isEmpty(mailSettings.companyAddress.companyName)) {
		// 	errors['company_address_company_name'] = 'Company name must not be blank.';
		// }
		return errors;
	}

	onChange = (value, id) => {
		this.setState(state => ({
			accountSettings: {
				...state.accountSettings,
				[id]: value
			},
			dirty: true
		}));
	};

	onFieldMappingChange = (fieldName, value) => {
		const {
			accountSettings: { fieldMappings }
		} = this.state;
		const newFieldMappings = { ...fieldMappings, [fieldName]: value };
		if (value === '') {
			delete newFieldMappings[fieldName];
		}
		this.onChange(newFieldMappings, 'fieldMappings');
	};

	renderMergeFields() {
		const { mergeFields } = this.props;
		const {
			accountSettings: { fieldMappings }
		} = this.state;
		const selectedMergeTags = Object.values(fieldMappings);
		return (
			<FormLayout>
				{Object.keys(FIELD_TYPES)
					.sort((a, b) => FIELD_TYPES[a].position - FIELD_TYPES[b].position)
					.map(fieldName => (
						<FieldMapping
							key={fieldName}
							shopifyField={fieldName}
							mergeFields={mergeFields}
							unavailableMergeFields={selectedMergeTags}
							value={fieldMappings[fieldName]}
							onChange={value => this.onFieldMappingChange(fieldName, value)}
						/>
					))}
			</FormLayout>
		);
	}

	render() {
		const {
			isSaving,
			onCancel,
			account: { mailChimpConnected, syncStatus }
		} = this.props;
		const { accountSettings, errors, dirty, showSaveToast, mergeFieldsLoaded } = this.state;
		if (!accountSettings) {
			return null;
		}
		const errorsAsList = Object.keys(errors).map(key => errors[key]);
		const initialSubscriberSyncDisabled = !mailChimpConnected || syncStatus.initialSyncStarted;
		return (
			<PageContainer
				title="Settings"
				primaryAction={{
					content: 'Save',
					onAction: this.onSave,
					disabled: isSaving || !dirty
				}}
				secondaryActions={[
					{
						content: 'Cancel',
						onAction: onCancel,
						disabled: isSaving
					}
				]}>
				<Layout>
					{errorsAsList.length > 0 && (
						<Layout.Section>
							<Banner
								title={`There ${errorsAsList.length === 1 ? 'is' : 'are'} ${errorsAsList.length} ${
									errorsAsList.length === 1 ? 'error' : 'errors'
								} with the settings:`}
								tone="critical">
								<List type="bullet">
									{errorsAsList.map((error, index) => (
										<List.Item key={index}>{error}</List.Item>
									))}
								</List>
							</Banner>
						</Layout.Section>
					)}
					<Layout.AnnotatedSection title="Settings" description="Adjust settings for how the connector syncs data.">
						<Card sectioned>
							<FormLayout>
								<ChoiceList
									title="Initial subscriber sync"
									choices={[
										{
											label: 'Keep any extra subscribers that are in Shopify',
											value: true,
											helpText:
												'Recommended if your subscriber list is not already in Mailchimp. If your audience is double opt-in, this will trigger confirmation emails for the extra Shopify subscribers.',
											disabled: initialSubscriberSyncDisabled
										},
										{
											label: 'Only keep subscribers that are in Mailchimp',
											value: false,
											helpText:
												'Recommended if your subscriber list is already in Mailchimp. Subscribed Shopify customers not in Mailchimp will be unsubscribed.',
											disabled: initialSubscriberSyncDisabled
										}
									]}
									selected={[accountSettings.shopifySubscribeExisting]}
									onChange={selections => this.onChange(selections[0], 'shopifySubscribeExisting')}
								/>
							</FormLayout>
						</Card>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection title="Mappings" description="Map Shopify customer data to Mailchimp merge fields.">
						<Card sectioned>
							{!mailChimpConnected && <div>Connect to Mailchimp to see available merge fields.</div>}
							{mailChimpConnected && !mergeFieldsLoaded && <SkeletonBodyText lines={3} />}
							{mailChimpConnected && mergeFieldsLoaded && this.renderMergeFields()}
						</Card>
					</Layout.AnnotatedSection>
				</Layout>
				{showSaveToast && <Toast content="Settings saved" onDismiss={() => {}} />}
			</PageContainer>
		);
	}
}
