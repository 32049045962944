import { Loading } from '@shopify/app-bridge-react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SupportChatContainer from '../containers/SupportChatContainer';
import DashboardContainer from '../containers/dashboard/DashboardContainer';
import MailChimpFinishConnectionContainer from '../containers/settings/MailChimpFinishConnectionContainer';
import SettingsContainer from '../containers/settings/SettingsContainer';

export type AppEmbedProps = {
	isLoading: boolean;
};

export default function AppEmbed({ isLoading }: AppEmbedProps) {
	return (
		<>
			<Routes>
				<Route path="settings/mailchimp-finish" element={<MailChimpFinishConnectionContainer />} />
				<Route path="settings" element={<SettingsContainer />} />
				<Route path="dashboard" element={<DashboardContainer />} />
				<Route path="" element={<Navigate to="/dashboard" />} />
			</Routes>
			<SupportChatContainer />
			{isLoading && <Loading />}
		</>
	);
}
