import { isNotBlank, ScrollToTop, WebVitalsWidget } from '@segunosoftware/equinox';
import '@segunosoftware/equinox/dist/styles.css';
import * as Sentry from '@sentry/react';
import '@shopify/polaris/build/esm/styles.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

if (import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'development') {
	Sentry.init({
		release: isNotBlank(__SEGUNO_APP_VERSION__) ? `mailchimp-connector-app@${__SEGUNO_APP_VERSION__}` : undefined,
		dsn: 'https://e913f172f1db4545a21601c17ced57b5@o155661.ingest.sentry.io/1444965',
		integrations: [new Sentry.BrowserTracing()],
		tracesSampleRate: 0.2,
		environment: import.meta.env.VITE_SENTRY_ENVIRONMENT
	});
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
	<StrictMode>
		<Router>
			<ScrollToTop>
				<App />
			</ScrollToTop>
			<WebVitalsWidget enabled={import.meta.env.VITE_WEB_VITALS_WIDGET_ENABLED === '1'} location="sw" />
		</Router>
	</StrictMode>
);
