import { LoadingScreen } from '@segunosoftware/equinox';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { getAccount } from '../reducers';
import AccountContainer from './AccountContainer';

ProtectedRoute.propTypes = {
	element: PropTypes.any.isRequired,
	account: PropTypes.object
};

function ProtectedRoute({ account, element, ...rest }) {
	const location = useLocation();
	return <AccountContainer>{account ? renderElement(account, location, element) : <LoadingScreen />}</AccountContainer>;
}

function renderElement(account, location, element) {
	const isSuspendedPath = location.pathname.indexOf('/suspended') === 0;
	const isFrozenPath = location.pathname.indexOf('/frozen') === 0;
	if (account.suspended && !isSuspendedPath) {
		return <Navigate to="/suspended" />;
	} else if (account.billingStatus === 'frozen' && !isFrozenPath) {
		return <Navigate to="/frozen" />;
	}
	if ((!account.suspended && isSuspendedPath) || (account.billingStatus !== 'frozen' && isFrozenPath)) {
		return <Navigate to="/" />;
	}
	return element;
}

const mapStateToProps = state => ({
	account: getAccount(state)
});

export default connect(mapStateToProps)(ProtectedRoute);
