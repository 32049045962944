import { connect } from 'react-redux';
import { mailChimpConnect } from '../../actions/mailChimp';
import MailChimpConnect from '../../components/oauth/MailChimpConnect';
import { getMailChimpConnectUrl, isMailChimpConnectLoading } from '../../reducers';

const mapStateToProps = state => ({
	redirectUrl: getMailChimpConnectUrl(state),
	redirectUrlLoading: isMailChimpConnectLoading(state)
});

const mapDispatchToProps = {
	onGetRedirectUrl: mailChimpConnect
};

export default connect(mapStateToProps, mapDispatchToProps)(MailChimpConnect);
