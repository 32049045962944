import { combineReducers } from 'redux';
import {
	COMPLETE_MAIL_CHIMP_CONNECTION_SUCCESS,
	LOAD_SYNC_STATUS_SUCCESS,
	SAVE_SETTINGS_SUCCESS,
	SET_REVIEW_SENTIMENT_SUCCESS,
	SET_REVIEW_STATUS_SUCCESS
} from '../actions/account';
import { WHOAMI, WHOAMI_ERROR, WHOAMI_SUCCESS } from '../actions/auth';
import { MAIL_CHIMP_CONNECTED_SUCCESS, MAIL_CHIMP_DISCONNECT_SUCCESS } from '../actions/mailChimp';

const hydrateAccount = account => {
	const hydrated = {
		...account
	};
	['createdAt', 'updatedAt'].forEach(dateName => {
		if (hydrated[dateName]) {
			hydrated[dateName] = new Date(hydrated[dateName]);
		}
	});
	return hydrated;
};

const account = (state = null, action) => {
	switch (action.type) {
		case WHOAMI_SUCCESS:
		case MAIL_CHIMP_CONNECTED_SUCCESS:
		case MAIL_CHIMP_DISCONNECT_SUCCESS:
		case COMPLETE_MAIL_CHIMP_CONNECTION_SUCCESS:
			return hydrateAccount(action.payload);
		case SET_REVIEW_SENTIMENT_SUCCESS:
			return { ...state, reviewSentiment: action.payload };
		case SET_REVIEW_STATUS_SUCCESS:
			return { ...state, reviewStatus: action.payload };
		case SAVE_SETTINGS_SUCCESS:
			return { ...state, accountSettings: action.payload };
		case LOAD_SYNC_STATUS_SUCCESS:
			return { ...state, syncStatus: action.payload };
		case WHOAMI_ERROR:
			return null;
		default:
			return state;
	}
};

const initialState = {
	isPerformingWhoAmI: false,
	loginFailureMessage: null
};

const auth = (state = initialState, action) => {
	switch (action.type) {
		case WHOAMI:
			return { ...state, isPerformingWhoAmI: true };
		case WHOAMI_SUCCESS:
			return { ...state, isPerformingWhoAmI: false };
		case WHOAMI_ERROR:
			return { ...state, loginFailureMessage: action.payload.error, isPerformingWhoAmI: false };
		default:
			return state;
	}
};

export default combineReducers({
	account,
	auth
});

export const isPerformingWhoAmI = state => state.auth.isPerformingWhoAmI;
export const getLoginFailureMessage = state => state.auth.loginFailureMessage;
export const getAccount = state => state.account;
