import { Stack } from '@segunosoftware/equinox';
import { Context } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import { Banner, Layout } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { Component } from 'react';
import styled from 'styled-components';
import SVGImage from './SVGImage';
import { activateMessage } from './SupportChat';

const reviewLink = 'https://apps.shopify.com/mailchimp-connect?reveal_new_review=true';

export default class ReviewBanner extends Component {
	static propTypes = {
		account: PropTypes.shape({
			syncStatus: PropTypes.shape({
				initialSyncComplete: PropTypes.bool.isRequired
			}).isRequired,
			reviewSentiment: PropTypes.oneOf(['unspecified', 'happy', 'sad']).isRequired,
			reviewStatus: PropTypes.oneOf(['ignored', 'reviewed', 'denied', 'deferred']).isRequired
		}).isRequired,
		onSetReviewSentiment: PropTypes.func.isRequired,
		onSetReviewStatus: PropTypes.func.isRequired
	};

	static contextType = Context;

	constructor(props) {
		super(props);
		const {
			account: {
				syncStatus: { initialSyncComplete },
				reviewSentiment,
				reviewStatus
			}
		} = props;
		const bannerVisible = initialSyncComplete && reviewSentiment !== 'sad' && reviewStatus === 'ignored';
		this.state = {
			reviewSentiment,
			reviewStatus,
			bannerVisible,
			bannerHidden: false
		};
	}

	onDismiss = () => {
		this.setState({
			bannerHidden: true
		});
	};

	onHappySentiment = () => {
		this.setReviewSentiment('happy');
	};

	onSadSentiment = () => {
		this.setReviewSentiment('sad');
	};

	setReviewSentiment = reviewSentiment => {
		const { onSetReviewSentiment } = this.props;
		this.setState({
			reviewSentiment
		});
		onSetReviewSentiment(reviewSentiment);
	};

	setReviewStatus = reviewStatus => {
		const { onSetReviewStatus } = this.props;
		this.setState({
			reviewStatus
		});
		onSetReviewStatus(reviewStatus);
	};

	renderSentiment() {
		return (
			<Banner title="How has your experience been?" tone="info">
				<Layout>
					<Layout.Section>The initial sync is now complete! Please let us know how your initial experience made you feel.</Layout.Section>
					<Layout.Section>
						<Stack alignment="center" distribution="leading" spacing="none">
							<SentimentIcon>
								<SVGImage viewBox="0 0 50 50" size={50} color="#637381" hoverColor="#01A0AC" onClick={this.onHappySentiment}>
									<path
										d="M16,30c0,3.32,4,6,9,6"
										style={{ fill: 'none', stroke: 'currentColor', strokeMiterlimit: 10, strokeWidth: '3px' }}
									/>
									<path
										d="M34,30c0,3.32-4,6-9,6"
										style={{ fill: 'none', stroke: 'currentColor', strokeMiterlimit: 10, strokeWidth: '3px' }}
									/>
									<path d="M25,1A24,24,0,1,0,49,25,24,24,0,0,0,25,1Zm0,44A20,20,0,1,1,45,25,20,20,0,0,1,25,45Z" />
									<circle cx="18.5" cy="19" r="2.5" />
									<circle cx="31.5" cy="19" r="2.5" />
								</SVGImage>
							</SentimentIcon>
							<SentimentIcon>
								<SVGImage viewBox="0 0 50 50" size={50} color="#637381" hoverColor="#01A0AC" onClick={this.onSadSentiment}>
									<path
										d="M16,36c0-3.32,4-6,9-6"
										style={{ fill: 'none', stroke: 'currentColor', strokeMiterlimit: 10, strokeWidth: '3px' }}
									/>
									<path
										d="M34,36c0-3.32-4-6-9-6"
										style={{ fill: 'none', stroke: 'currentColor', strokeMiterlimit: 10, strokeWidth: '3px' }}
									/>
									<path d="M25,1A24,24,0,1,0,49,25,24,24,0,0,0,25,1Zm0,44A20,20,0,1,1,45,25,20,20,0,0,1,25,45Z" />
									<circle cx="18.5" cy="19" r="2.5" />
									<circle cx="31.5" cy="19" r="2.5" />
								</SVGImage>
							</SentimentIcon>
						</Stack>
					</Layout.Section>
				</Layout>
			</Banner>
		);
	}

	sendToReview = () => {
		const app = this.context;
		const redirect = Redirect.create(app);
		redirect.dispatch(Redirect.Action.REMOTE, {
			url: reviewLink,
			newContext: true
		});
		this.setReviewStatus('reviewed');
		this.onDismiss();
	};

	renderRequestReview() {
		return (
			<Banner title="Tell us more..." tone="info" action={{ content: 'Write a review', onAction: this.sendToReview }}>
				We're so glad you had a nice experience and would love to hear your thoughts. Please feel free to contact us directly but we would
				greatly appreciate you taking a minute to write a quick review. Reviews are incredibly helpful for us and your fellow merchants.
				Thanks so much for using our app!
			</Banner>
		);
	}

	submitATicket = () => {
		activateMessage('Feedback', 'Hello. I think Mail Connect could be improved by ');
		this.onDismiss();
	};

	reviewDenied = () => {
		this.setReviewStatus('denied');
		this.onDismiss();
	};

	renderProvideSupport() {
		return (
			<Banner
				title="How can we help?"
				tone="info"
				action={{ content: 'Send us a message', onAction: this.submitATicket }}
				onDismiss={this.onDismiss}>
				We're very sorry to hear you have not had a stellar experience. We would love to know why so we can make it right and help ensure
				your needs will be met going forward. Please send us a message to share your thoughts.
			</Banner>
		);
	}

	renderReview() {
		const { reviewSentiment } = this.state;
		if (reviewSentiment === 'unspecified') {
			return this.renderSentiment();
		} else if (reviewSentiment === 'sad') {
			return this.renderProvideSupport();
		}
		return this.renderRequestReview();
	}

	render() {
		const { bannerVisible, bannerHidden } = this.state;
		if (bannerHidden || !bannerVisible) {
			return null;
		}
		const reviewContent = this.renderReview();
		if (!reviewContent) {
			return null;
		}
		return <Layout.Section>{reviewContent}</Layout.Section>;
	}
}

const SentimentIcon = styled.span`
	display: inline-block;
	margin-right: 50px;
`;
