import { callApi } from '../middleware/api';

export const MAIL_CHIMP_CONNECT = 'mailChimp/MAIL_CHIMP_CONNECT';
export const MAIL_CHIMP_CONNECT_SUCCESS = 'mailChimp/MAIL_CHIMP_CONNECT_SUCCESS';
export const MAIL_CHIMP_CONNECT_ERROR = 'mailChimp/MAIL_CHIMP_CONNECT_ERROR';

export const MAIL_CHIMP_CONNECTED = 'mailChimp/MAIL_CHIMP_CONNECTED';
export const MAIL_CHIMP_CONNECTED_SUCCESS = 'mailChimp/MAIL_CHIMP_CONNECTED_SUCCESS';
export const MAIL_CHIMP_CONNECTED_ERROR = 'mailChimp/MAIL_CHIMP_CONNECTED_ERROR';

export const MAIL_CHIMP_DISCONNECT = 'mailChimp/MAIL_CHIMP_DISCONNECT';
export const MAIL_CHIMP_DISCONNECT_SUCCESS = 'mailChimp/MAIL_CHIMP_DISCONNECT_SUCCESS';
export const MAIL_CHIMP_DISCONNECT_ERROR = 'mailChimp/MAIL_CHIMP_DISCONNECT_ERROR';

export const MAIL_CHIMP_GET_LISTS = 'mailChimp/MAIL_CHIMP_GET_LISTS';
export const MAIL_CHIMP_GET_LISTS_SUCCESS = 'mailChimp/MAIL_CHIMP_GET_LISTS_SUCCESS';
export const MAIL_CHIMP_GET_LISTS_ERROR = 'mailChimp/MAIL_CHIMP_GET_LISTS_ERROR';

export const MAIL_CHIMP_GET_LIST = 'mailChimp/MAIL_CHIMP_GET_LIST';
export const MAIL_CHIMP_GET_LIST_SUCCESS = 'mailChimp/MAIL_CHIMP_GET_LIST_SUCCESS';
export const MAIL_CHIMP_GET_LIST_ERROR = 'mailChimp/MAIL_CHIMP_GET_LIST_ERROR';

export const MAIL_CHIMP_GET_MERGE_FIELDS = 'mailChimp/MAIL_CHIMP_GET_MERGE_FIELDS';
export const MAIL_CHIMP_GET_MERGE_FIELDS_SUCCESS = 'mailChimp/MAIL_CHIMP_GET_MERGE_FIELDS_SUCCESS';
export const MAIL_CHIMP_GET_MERGE_FIELDS_ERROR = 'mailChimp/MAIL_CHIMP_GET_MERGE_FIELDS_ERROR';

export const mailChimpConnect = () =>
	callApi('/oauth/mailchimp/connect', {
		before: MAIL_CHIMP_CONNECT,
		success: MAIL_CHIMP_CONNECT_SUCCESS,
		error: MAIL_CHIMP_CONNECT_ERROR,
		config: {
			method: 'POST'
		}
	});

export const mailChimpConnected = (shop, code) =>
	callApi(`/oauth/mailchimp/connected?shop=${shop}&code=${code}`, {
		before: MAIL_CHIMP_CONNECTED,
		success: MAIL_CHIMP_CONNECTED_SUCCESS,
		error: MAIL_CHIMP_CONNECTED_ERROR
	});

export const mailChimpDisconnect = () =>
	callApi('/oauth/mailchimp/disconnect', {
		before: MAIL_CHIMP_DISCONNECT,
		success: MAIL_CHIMP_DISCONNECT_SUCCESS,
		error: MAIL_CHIMP_DISCONNECT_ERROR,
		config: {
			method: 'POST'
		}
	});

export const mailChimpGetLists = () =>
	callApi('/mailchimp/lists', {
		before: MAIL_CHIMP_GET_LISTS,
		success: MAIL_CHIMP_GET_LISTS_SUCCESS,
		error: MAIL_CHIMP_GET_LISTS_ERROR
	});

const mailChimpGetListAction = id => ({
	type: MAIL_CHIMP_GET_LIST,
	payload: {
		id
	}
});

const mailChimpGetListErrorAction = id => ({
	type: MAIL_CHIMP_GET_LIST_ERROR,
	payload: {
		id
	}
});

export const mailChimpGetList = id =>
	callApi(`/mailchimp/lists/${id}`, {
		before: mailChimpGetListAction(id),
		success: MAIL_CHIMP_GET_LIST_SUCCESS,
		error: mailChimpGetListErrorAction(id)
	});

export const mailChimpGetMergeFields = () =>
	callApi('/mailchimp/merge-fields', {
		before: MAIL_CHIMP_GET_MERGE_FIELDS,
		success: MAIL_CHIMP_GET_MERGE_FIELDS_SUCCESS,
		error: MAIL_CHIMP_GET_MERGE_FIELDS_ERROR
	});
