import { combineReducers } from 'redux';
import {
	SHOPIFY_CALLBACK,
	SHOPIFY_CALLBACK_ERROR,
	SHOPIFY_CALLBACK_SUCCESS,
	SHOPIFY_INSTALL,
	SHOPIFY_INSTALL_ERROR,
	SHOPIFY_INSTALL_SUCCESS
} from '../actions/oauth';

const installShopify = (state = { redirectUrl: null }, action) => {
	switch (action.type) {
		case SHOPIFY_INSTALL_SUCCESS:
		case SHOPIFY_CALLBACK_SUCCESS:
			return { ...state, redirectUrl: action.payload.redirectUrl };
		case SHOPIFY_CALLBACK:
		case SHOPIFY_CALLBACK_ERROR:
		case SHOPIFY_INSTALL:
		case SHOPIFY_INSTALL_ERROR:
			return { ...state, redirectUrl: null };
		default:
			return state;
	}
};

export default combineReducers({
	installShopify
});

export const getInstallShopifyRedirectUrl = state => state.installShopify.redirectUrl;
