import { Stack } from '@segunosoftware/equinox';
import { Button, Icon, Link } from '@shopify/polaris';
import { XIcon } from '@shopify/polaris-icons';
import PropTypes from 'prop-types';
import { Component } from 'react';
import styled from 'styled-components';

export default class SuggestedVideo extends Component {
	static propTypes = {
		title: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		viewContent: PropTypes.string.isRequired,
		linkContent: PropTypes.string.isRequired,
		linkAction: PropTypes.func.isRequired,
		video: PropTypes.string.isRequired,
		previewImage: PropTypes.string.isRequired
	};

	state = {
		showingVideo: false
	};

	onToggleVideo = () => {
		this.setState(state => ({
			showingVideo: !state.showingVideo
		}));
	};

	render() {
		const { video, previewImage, title, description, viewContent, linkContent, linkAction } = this.props;
		const { showingVideo } = this.state;
		return (
			<Container showingVideo={showingVideo}>
				{showingVideo && (
					<ResponsiveVideo>
						<ButtonContainer>
							<Button onClick={this.onToggleVideo} variant="plain">
								<Icon source={XIcon} tone="base" />
							</Button>
						</ButtonContainer>
						<iframe
							title="Suggested video"
							width="560"
							height="315"
							src={video}
							frameBorder="0"
							allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen></iframe>
					</ResponsiveVideo>
				)}
				{!showingVideo && (
					<ImageContainer onClick={this.onToggleVideo}>
						<PreviewImage src={previewImage} alt="Preview" />
					</ImageContainer>
				)}
				<ContentContainer>
					<Title>{title}</Title>
					<div>{description}</div>
					{!showingVideo && (
						<ActionContainer>
							<Stack alignment="center">
								<Button onClick={this.onToggleVideo}>{viewContent}</Button>
								{linkAction && linkContent && <Link onClick={linkAction}>{linkContent}</Link>}
							</Stack>
						</ActionContainer>
					)}
				</ContentContainer>
			</Container>
		);
	}
}

const ButtonContainer = styled.div`
	display: none;
	position: absolute;
	z-index: 400;
	top: 0;
	right: 0;
	margin: 1rem;
	padding: 10px;
	background-color: #ffffff;
	border-radius: 6px;
	align-items: center;
	justify-content: center;
	opacity: 0.7;
	transition: opacity 100ms ease-in-out;
	&:hover {
		opacity: 1;
	}
`;

const ResponsiveVideo = styled.div`
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;

	iframe {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
	}

	&:hover ${ButtonContainer} {
		display: flex;
	}
`;

const Container = styled.div`
	display: flex;
	flex-flow: ${({ showingVideo }) => (showingVideo ? 'column wrap' : 'row nowrap')};
	@media (max-width: 769px) {
		flex-flow: column wrap;
	}
`;

const ImageContainer = styled.div`
	position: relative;
	width: 13.75rem;
	flex-shrink: 0;
	cursor: pointer;
	border-right: solid 1px #dfe3e8;
	@media (max-width: 769px) {
		width: 100%;
		height: 9.375rem;
		border-right: none;
	}
	&::after {
		content: url('/images/suggested-video.svg');
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -30px;
		margin-top: -30px;
		width: 60px;
		height: 60px;
		background-color: transparent;
		transition: all 100ms ease-in;
		filter: grayscale(100%);
		opacity: 0.6;
		padding: 6px;
	}
	&:hover {
		&::after {
			filter: grayscale(0%);
			opacity: 0.8;
		}
	}
`;

const PreviewImage = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: contain;
	background-color: #ffe019;
`;

const ContentContainer = styled.div`
	padding: 1.25rem;
`;

const Title = styled.div`
	font-size: 1rem;
	font-weight: 600;
	margin-bottom: 1rem;
	@media (max-width: 769px) {
		font-size: 1.0625rem;
	}
`;

const ActionContainer = styled.div`
	margin-top: 1.25rem;
`;
