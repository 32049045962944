import { Card, Stack } from '@segunosoftware/equinox';
import { Link } from '@shopify/polaris';
import { Component } from 'react';
import styled from 'styled-components';

const ITEMS = [
	{
		src: '/images/dashboard/ad_seguno.png',
		alt: 'Seguno',
		url: 'https://apps.shopify.com/seguno?ref=seguno&channel=app-connect',
		description:
			'Automatically include unique discount codes in your emails! Create, manage, and track your email marketing without ever leaving Shopify.'
	},
	{
		src: '/images/dashboard/ad_banner.png',
		alt: 'Dynamic Banner Suite',
		url: 'https://apps.shopify.com/coupon-banner-with-countdown-carousel?ref=seguno&channel=app-connect',
		description: 'Display a banner for email capture, announcements, free shipping offers, or discount promotions.'
	},
	{
		src: '/images/dashboard/ad_bulk.png',
		alt: 'Bulk Discount Code Bot',
		url: 'https://apps.shopify.com/bulk-discount-generator?ref=seguno&channel=app-connect',
		description: (
			<span>
				Create sets of unique discount codes, often used in email marketing. Not needed if using{' '}
				<Link url="https://apps.shopify.com/seguno?ref=seguno&amp;channel=app-connect" external>
					Seguno
				</Link>{' '}
				as unique code creation is built right in.
			</span>
		)
	}
];

export default class AdsCard extends Component {
	render() {
		return (
			<Card title="Other apps by Seguno" sectioned>
				<Stack spacing="extraLoose" vertical>
					{ITEMS.map(ad => (
						<Stack key={ad.url} spacing="extraTight" vertical>
							<Link url={ad.url} external>
								<AdImage src={ad.src} alt={ad.alt} width="270" height="122" />
							</Link>
							<p>
								{ad.description}{' '}
								<Link url={ad.url} external>
									Learn more
								</Link>
							</p>
						</Stack>
					))}
				</Stack>
			</Card>
		);
	}
}

const AdImage = styled.img`
	border: solid 1px #c4cdd5;
	border-radius: 3px;
	overflow: hidden;
	width: 100%;
	height: auto;
	min-height: 122px;
	max-width: 100%;
`;
