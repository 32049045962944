import { getNumber } from '@segunosoftware/equinox';
import { Select } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { Component } from 'react';

export default class MailChimpListPicker extends Component {
	static propTypes = {
		onChange: PropTypes.func.isRequired,
		onLoadLists: PropTypes.func.isRequired,
		lists: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired
			})
		).isRequired,
		loading: PropTypes.bool,
		disableAutoSelect: PropTypes.bool,
		list: PropTypes.string
	};

	static defaultProps = {
		loading: false,
		disableAutoSelect: false
	};

	componentDidMount() {
		const { onLoadLists, lists, loading } = this.props;
		if (lists.length === 0 && !loading) {
			onLoadLists();
		}
		this.checkIfOneList();
	}

	componentDidUpdate(prevProps, prevState) {
		this.checkIfOneList();
	}

	checkIfOneList() {
		const { disableAutoSelect, lists, list, onChange } = this.props;
		if (disableAutoSelect || list) {
			return;
		}
		if (lists.length === 1) {
			onChange(lists[0].id);
		}
	}

	render() {
		const { lists, list, loading, disabled, onChange, disableAutoSelect, ...rest } = this.props;
		return (
			<Select
				placeholder={loading ? 'Loading...' : 'Select list'}
				{...rest}
				disabled={loading || disabled || lists.length === 0 || (lists.length === 1 && !disableAutoSelect)}
				options={lists.map(l => ({
					value: l.id.toString(),
					label: `${l.name} (${getNumber(l.stats.memberCount)} ${l.stats.memberCount === 1 ? 'subscriber' : 'subscribers'})`
				}))}
				value={list}
				onChange={value => onChange(value)}
			/>
		);
	}
}
