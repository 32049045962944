import { combineReducers } from 'redux';
import {
	COMPLETE_MAIL_CHIMP_CONNECTION,
	COMPLETE_MAIL_CHIMP_CONNECTION_ERROR,
	COMPLETE_MAIL_CHIMP_CONNECTION_SUCCESS,
	LOAD_SYNC_STATUS,
	LOAD_SYNC_STATUS_ERROR,
	LOAD_SYNC_STATUS_SUCCESS,
	SAVE_SETTINGS,
	SAVE_SETTINGS_ERROR,
	SAVE_SETTINGS_SUCCESS,
	START_SYNC,
	START_SYNC_ERROR,
	START_SYNC_SUCCESS
} from '../actions/account';

const isSavingSettings = (state = false, action) => {
	switch (action.type) {
		case SAVE_SETTINGS:
		case SAVE_SETTINGS_SUCCESS:
		case SAVE_SETTINGS_ERROR:
			return action.type === SAVE_SETTINGS;
		default:
			return state;
	}
};

const finishMailChimpConnectionLoading = (state = false, action) => {
	switch (action.type) {
		case COMPLETE_MAIL_CHIMP_CONNECTION:
		case COMPLETE_MAIL_CHIMP_CONNECTION_SUCCESS:
		case COMPLETE_MAIL_CHIMP_CONNECTION_ERROR:
			return action.type === COMPLETE_MAIL_CHIMP_CONNECTION;
		default:
			return state;
	}
};

const syncStatusLoading = (state = false, action) => {
	switch (action.type) {
		case LOAD_SYNC_STATUS:
		case LOAD_SYNC_STATUS_SUCCESS:
		case LOAD_SYNC_STATUS_ERROR:
			return action.type === LOAD_SYNC_STATUS;
		default:
			return state;
	}
};

const startSyncLoading = (state = false, action) => {
	switch (action.type) {
		case START_SYNC:
		case START_SYNC_SUCCESS:
		case START_SYNC_ERROR:
			return action.type === START_SYNC;
		default:
			return state;
	}
};

export default combineReducers({
	isSavingSettings,
	finishMailChimpConnectionLoading,
	syncStatusLoading,
	startSyncLoading
});

export const isSettingsSaving = state => state.isSavingSettings;
export const isFinishMailChimpConnectionLoading = state => state.finishMailChimpConnectionLoading;
export const isSyncStatusLoading = state => state.syncStatusLoading;
export const isStartSyncLoading = state => state.startSyncLoading;
