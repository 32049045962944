import PropTypes from 'prop-types';
import { Component } from 'react';
import { FeatureFlags } from '../utils/feature-flags';

const BEACON_ID = import.meta.env.VITE_BEACON_ID;
const INTERCOM_ID = import.meta.env.VITE_INTERCOM_ID;

export const activateSupport = () => {
	if (window.Intercom && FeatureFlags.INTERCOM_MESSENGER_ENABLED) {
		window.Intercom('show');
		return;
	}
	if (window.Beacon) {
		window.Beacon('open');
	}
};

export const activateMessage = (subject, message = '') => {
	if (window.Intercom && FeatureFlags.INTERCOM_MESSENGER_ENABLED) {
		activateSupport();
		window.Intercom('showNewMessage', `${subject} ${message}`);
		return;
	}
	if (window.Beacon) {
		activateSupport();
		window.Beacon('navigate', '/ask/message/');
		window.Beacon('prefill', {
			subject,
			text: message
		});
	}
};

export const openArticle = articleId => {
	if (window.Intercom && FeatureFlags.INTERCOM_MESSENGER_ENABLED) {
		window.Intercom('showArticle', articleId);
		return;
	}
	if (window.Beacon) {
		window.Beacon('article', articleId);
	}
};

const submitPageViewEvent = () => {
	if (window.Beacon) {
		window.Beacon('event', {
			type: 'page-viewed',
			url: window.location.href,
			title: document.title
		});
	}
	if (window.Intercom && FeatureFlags.INTERCOM_ENABLED) {
		window.Intercom('update');
	}
};

export default class SupportChat extends Component {
	static propTypes = {
		account: PropTypes.shape({
			id: PropTypes.number.isRequired,
			ownerName: PropTypes.string.isRequired,
			ownerEmail: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			shop: PropTypes.string.isRequired,
			domain: PropTypes.string.isRequired,
			billingStatus: PropTypes.string.isRequired,
			platformPlan: PropTypes.string.isRequired,
			userHash: PropTypes.string.isRequired,
			phone: PropTypes.string
		})
	};

	constructor(props) {
		super(props);

		this.state = {
			scriptsInited: false
		};
	}

	componentDidMount() {
		// HelpScout
		if (!window.Beacon && !FeatureFlags.INTERCOM_MESSENGER_ENABLED) {
			const scriptTag = document.createElement('script');
			scriptTag.type = 'text/javascript';
			scriptTag.async = true;
			scriptTag.src = 'https://beacon-v2.helpscout.net';
			document.body.appendChild(scriptTag);
			window.Beacon = function (t, n, a) {
				// @ts-ignore
				window.Beacon.readyQueue.push({ method: t, options: n, data: a });
			};
			// @ts-ignore
			window.Beacon.readyQueue = [];

			window.Beacon('init', BEACON_ID);
			submitPageViewEvent();
		}

		// Intercom
		if (FeatureFlags.INTERCOM_ENABLED && !window.Intercom) {
			window.Intercom = function () {
				// @ts-ignore
				window.Intercom.c(arguments);
			};
			// @ts-ignore
			window.Intercom.q = [];
			// @ts-ignore
			window.Intercom.c = function (args) {
				// @ts-ignore
				window.Intercom.q.push(args);
			};
			const scriptTag = document.createElement('script');
			scriptTag.type = 'text/javascript';
			scriptTag.async = true;
			scriptTag.src = `https://widget.intercom.io/widget/${INTERCOM_ID}`;
			document.body.appendChild(scriptTag);
		}

		this.identifyUser();
	}

	componentWillUnmount() {
		// The double render from StrictMode breaks HelpScout since the init/destroy is so fast
		if (import.meta.env.PROD && window.Beacon) {
			window.Beacon('destroy');
		}
		if (import.meta.env.PROD && window.Intercom && FeatureFlags.INTERCOM_MESSENGER_ENABLED) {
			window.Intercom('shutdown');
		}
	}

	identifyUser() {
		const { account } = this.props;
		if (account && window.Beacon) {
			window.Beacon('identify', {
				email: account.ownerEmail,
				signature: account.userHash,
				name: account.ownerName,
				phone: account.phone,
				shop_name: account.name,
				shop: account.shop,
				domain: account.domain,
				billing_status: account.billingStatus,
				platform_plan: account.platformPlan,
				admin_url: `https://admin.seguno.com/mailchimp-connector-accounts/${account.id}`
			});
		}
		if (account && window.Intercom && FeatureFlags.INTERCOM_ENABLED) {
			const companyId = account.shop.substring(0, account.shop.indexOf('.'));
			window.Intercom('boot', {
				api_base: 'https://api-iam.intercom.io',
				app_id: INTERCOM_ID,
				name: account.ownerName,
				email: account.ownerEmail,
				user_hash: account.supportUserHash,
				created_at: account.createdAt.getTime() / 1000,
				company: {
					id: companyId
				}
			});
			submitPageViewEvent();
		}
	}

	render() {
		return null;
	}
}
