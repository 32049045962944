import { combineReducers } from 'redux';
import {
	MAIL_CHIMP_CONNECT,
	MAIL_CHIMP_CONNECTED,
	MAIL_CHIMP_CONNECTED_ERROR,
	MAIL_CHIMP_CONNECTED_SUCCESS,
	MAIL_CHIMP_CONNECT_ERROR,
	MAIL_CHIMP_CONNECT_SUCCESS,
	MAIL_CHIMP_DISCONNECT,
	MAIL_CHIMP_DISCONNECT_ERROR,
	MAIL_CHIMP_DISCONNECT_SUCCESS,
	MAIL_CHIMP_GET_LIST,
	MAIL_CHIMP_GET_LISTS,
	MAIL_CHIMP_GET_LISTS_ERROR,
	MAIL_CHIMP_GET_LISTS_SUCCESS,
	MAIL_CHIMP_GET_LIST_ERROR,
	MAIL_CHIMP_GET_LIST_SUCCESS,
	MAIL_CHIMP_GET_MERGE_FIELDS,
	MAIL_CHIMP_GET_MERGE_FIELDS_ERROR,
	MAIL_CHIMP_GET_MERGE_FIELDS_SUCCESS
} from '../actions/mailChimp';

const mailChimpConnect = (state = null, action) => {
	switch (action.type) {
		case MAIL_CHIMP_CONNECT:
		case MAIL_CHIMP_CONNECT_ERROR:
			return null;
		case MAIL_CHIMP_CONNECT_SUCCESS:
			return action.payload.redirectUrl;
		default:
			return state;
	}
};

const mailChimpConnectedError = (state = null, action) => {
	switch (action.type) {
		case MAIL_CHIMP_CONNECTED:
		case MAIL_CHIMP_CONNECTED_SUCCESS:
			return null;
		case MAIL_CHIMP_CONNECTED_ERROR:
			return action.payload.error;
		default:
			return state;
	}
};

const mailChimpConnectLoading = (state = false, action) => {
	switch (action.type) {
		case MAIL_CHIMP_CONNECT:
		case MAIL_CHIMP_CONNECT_SUCCESS:
		case MAIL_CHIMP_CONNECT_ERROR:
			return action.type === MAIL_CHIMP_CONNECT;
		default:
			return state;
	}
};

const mailChimpConnectedLoading = (state = false, action) => {
	switch (action.type) {
		case MAIL_CHIMP_CONNECTED:
		case MAIL_CHIMP_CONNECTED_SUCCESS:
		case MAIL_CHIMP_CONNECTED_ERROR:
			return action.type === MAIL_CHIMP_CONNECTED;
		default:
			return state;
	}
};

const mailChimpDisconnectLoading = (state = false, action) => {
	switch (action.type) {
		case MAIL_CHIMP_DISCONNECT:
		case MAIL_CHIMP_DISCONNECT_SUCCESS:
		case MAIL_CHIMP_DISCONNECT_ERROR:
			return action.type === MAIL_CHIMP_DISCONNECT;
		default:
			return state;
	}
};

const mailChimpLists = (state = {}, action) => {
	switch (action.type) {
		case MAIL_CHIMP_GET_LISTS_SUCCESS:
			return action.payload.reduce((acc, curr) => {
				acc[curr.id] = curr;
				return acc;
			}, {});
		case MAIL_CHIMP_GET_LIST_SUCCESS:
			return {
				...state,
				[action.payload.id]: action.payload
			};
		default:
			return state;
	}
};

const mailChimpListsLoading = (state = false, action) => {
	switch (action.type) {
		case MAIL_CHIMP_GET_LISTS:
		case MAIL_CHIMP_GET_LISTS_SUCCESS:
		case MAIL_CHIMP_GET_LISTS_ERROR:
			return action.type === MAIL_CHIMP_GET_LISTS;
		default:
			return state;
	}
};

const mailChimpListLoading = (state = {}, action) => {
	switch (action.type) {
		case MAIL_CHIMP_GET_LIST:
		case MAIL_CHIMP_GET_LIST_SUCCESS:
		case MAIL_CHIMP_GET_LIST_ERROR:
			return {
				...state,
				[action.payload.id]: action.type === MAIL_CHIMP_GET_LIST
			};
		default:
			return state;
	}
};

const mergeFields = (state = [], action) => {
	switch (action.type) {
		case MAIL_CHIMP_GET_MERGE_FIELDS_SUCCESS:
			return action.payload;
		default:
			return state;
	}
};

const mergeFieldsLoading = (state = false, action) => {
	switch (action.type) {
		case MAIL_CHIMP_GET_MERGE_FIELDS:
		case MAIL_CHIMP_GET_MERGE_FIELDS_SUCCESS:
		case MAIL_CHIMP_GET_MERGE_FIELDS_ERROR:
			return action.type === MAIL_CHIMP_GET_MERGE_FIELDS;
		default:
			return state;
	}
};

export default combineReducers({
	mailChimpConnect,
	mailChimpConnectLoading,
	mailChimpConnectedLoading,
	mailChimpDisconnectLoading,
	mailChimpConnectedError,
	mailChimpLists,
	mailChimpListsLoading,
	mailChimpListLoading,
	mergeFields,
	mergeFieldsLoading
});

export const getMailChimpConnectUrl = state => state.mailChimpConnect;
export const isMailChimpConnectLoading = state => state.mailChimpConnectLoading;
export const isMailChimpConnectedLoading = state => state.mailChimpConnectedLoading;
export const isMailChimpDisconnectLoading = state => state.mailChimpDisconnectLoading;
export const getMailChimpConnectedError = state => state.mailChimpConnectedError;
export const getMailChimpLists = state => Object.keys(state.mailChimpLists).map(k => state.mailChimpLists[k]);
export const isMailChimpListsLoading = state => state.mailChimpListsLoading;
export const getMailChimpList = (state, id) => state.mailChimpLists[id];
export const isMailChimpListLoading = (state, id) => !!state.mailChimpListLoading[id];
export const getMailChimpMergeFields = state => state.mergeFields;
export const isMailChimpMergeFieldsLoading = state => state.mergeFieldsLoading;
