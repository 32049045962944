import { Card } from '@segunosoftware/equinox';
import { Button, Checkbox, FormLayout, Layout, Modal } from '@shopify/polaris';
import PropTypes from 'prop-types';
import { Component } from 'react';
import PageContainer from '../../containers/PageContainer';
import MailChimpConnectionContainer from '../../containers/dashboard/MailChimpConnectionContainer';
import SyncStatusContainer from '../../containers/dashboard/SyncStatusContainer';
import SuggestedVideo from '../SuggestedVideo';
import AdsCard from './AdsCard';
import Instructions from './Instructions';

export default class Dashboard extends Component {
	static propTypes = {
		account: PropTypes.shape({
			syncStatus: PropTypes.shape({
				initialSyncStarted: PropTypes.bool.isRequired,
				initialSyncComplete: PropTypes.bool.isRequired
			}).isRequired,
			mailChimpConnected: PropTypes.bool.isRequired
		}).isRequired,
		onStartSync: PropTypes.func.isRequired,
		isStartSyncLoading: PropTypes.bool.isRequired
	};

	state = {
		syncStarted: false,
		startSyncFinishedLoading: false,
		syncConfirmOpen: false,
		confirmedBackup: false
	};

	componentDidUpdate(prevProps, prevState) {
		const { isStartSyncLoading } = this.props;
		const { startSyncFinishedLoading } = this.state;
		if (!isStartSyncLoading && isStartSyncLoading !== prevProps.isStartSyncLoading && !startSyncFinishedLoading) {
			this.setState({
				startSyncFinishedLoading: true
			});
		}
	}

	onStartSync = () => {
		const { onStartSync } = this.props;
		this.setState({
			syncStarted: true,
			confirmedBackup: false,
			syncConfirmOpen: false
		});
		onStartSync();
	};

	onOpenSyncConfirm = () => {
		this.setState({
			syncConfirmOpen: true
		});
	};

	onCloseSyncConfirm = () => {
		this.setState({
			syncConfirmOpen: false
		});
	};

	isSyncComplete() {
		const {
			account: {
				syncStatus: { initialSyncComplete }
			}
		} = this.props;
		return initialSyncComplete;
	}

	renderSyncWidget() {
		const {
			account: {
				syncStatus: { initialSyncStarted },
				mailChimpConnected
			}
		} = this.props;
		const { syncStarted, startSyncFinishedLoading } = this.state;
		if (mailChimpConnected && ((syncStarted && startSyncFinishedLoading) || initialSyncStarted)) {
			return <SyncStatusContainer />;
		}
		return (
			<Button
				onClick={this.onOpenSyncConfirm}
				loading={syncStarted}
				disabled={!mailChimpConnected}
				variant={mailChimpConnected ? 'primary' : undefined}
				fullWidth>
				Start sync
			</Button>
		);
	}

	render() {
		const { syncConfirmOpen, confirmedBackup } = this.state;
		return (
			<PageContainer title="Dashboard">
				<Layout>
					<Layout.Section>
						<Layout sectioned>
							<Card>
								<Card.Section>
									<MailChimpConnectionContainer />
								</Card.Section>
								<Card.Section subdued>{this.renderSyncWidget()}</Card.Section>
							</Card>
							<Card>
								<SuggestedVideo
									title="Tips for success"
									description="This video describes a quick overview of the app's functionality and then provides 5 tips for success. Watch after you have read through all of the detailed instructions but before you start the sync."
									viewContent="Watch video"
									previewImage="/images/dashboard/tips_video.png"
									video="https://www.youtube.com/embed/1JkNJtMR8uI?rel=0;&amp;autoplay=1"
								/>
							</Card>
							<Card title="Instructions" sectioned>
								<Instructions />
							</Card>
						</Layout>
					</Layout.Section>
					<Layout.Section variant="oneThird">
						<AdsCard />
					</Layout.Section>
				</Layout>
				<Modal
					title="Start sync"
					primaryAction={{ content: 'Start sync', onAction: this.onStartSync, disabled: !confirmedBackup }}
					secondaryActions={[{ content: 'Cancel', onAction: this.onCloseSyncConfirm }]}
					onClose={this.onCloseSyncConfirm}
					open={syncConfirmOpen}>
					<Modal.Section>
						<FormLayout>
							<div>
								This will begin the sync process. Make sure to follow all of the instructions in order to ensure a smooth onboarding
								process.
							</div>
							<Checkbox
								label="I have exported a backup copy of my customers from Shopify as well as my contacts from Mailchimp"
								checked={confirmedBackup}
								onChange={confirmed => this.setState({ confirmedBackup: confirmed })}
							/>
						</FormLayout>
					</Modal.Section>
				</Modal>
			</PageContainer>
		);
	}
}
