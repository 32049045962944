import PropTypes from 'prop-types';
import { Component } from 'react';
import styled from 'styled-components';

export default class SVGImage extends Component {
	static propTypes = {
		viewBox: PropTypes.string.isRequired,
		onClick: PropTypes.func,
		size: PropTypes.number,
		color: PropTypes.string,
		hoverColor: PropTypes.string
	};

	static defaultProps = {
		size: 20,
		color: '#000',
		hoverColor: '#000'
	};

	onClick = () => {
		const { onClick } = this.props;
		onClick && onClick();
	};

	render() {
		const { viewBox, children, onClick, ...rest } = this.props;
		const hasOnClick = typeof onClick === 'function';
		return (
			<StyledSVG {...rest} viewBox={viewBox} hasOnClick={hasOnClick} onClick={this.onClick}>
				{children}
			</StyledSVG>
		);
	}
}

const StyledSVG = styled.svg`
	width: ${props => props.size}px;
	height: ${props => props.size}px;
	fill: ${props => props.color};
	color: ${props => props.color};
	cursor: ${props => (props.hasOnClick ? 'pointer' : 'default')};
	&:hover {
		fill: ${props => props.hoverColor};
		color: ${props => props.hoverColor};
	}
`;
